<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin:: Add customer-->
    <div class="row gutter-b">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Customer</h6>
          </template>
          <div class="row">
            <div class="col-md-6">
              <!-- <div class="mb-4">
                <h6 class="mb-1">
                  Parent
                </h6>
                <div>
                  {{branch.parent}}
                </div>
              </div> -->
              <div class="mb-4">
                <h6 class="mb-1">ID</h6>
                <div>
                  {{ branch.id }}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Nama Client</h6>
                <div>
                  {{ branch.name }}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Email</h6>
                <div>
                  {{ branch.email }}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">No Telpon</h6>
                <div>
                  {{ branch.phone || "" }}
                </div>
              </div>
              <!-- <div class="mb-4">
                <h6 class="mb-1">
                  Tanggal Berlaku Perjanjian
                </h6>
                <div>
                  {{branch.address}}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">
                  No Kontrak
                </h6>
                <div>
                  {{customer.address}}
                </div>
              </div> -->
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">Alamat Lengkap</h6>
                <div>
                  {{ branch.address }}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Provinsi</h6>
                <div>
                  {{ branch?.province?.name }}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kabupaten/Kota</h6>
                <div>
                  {{ branch?.regency?.name }}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kecamatan</h6>
                <div>
                  {{ branch?.district?.name }}
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kelurahan</h6>
                <div>
                  {{ branch?.village?.name }}
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!-- <div class="row gutter-b">
      <div class="col-md-4">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Services</h6>
          </template>
          <b-form-checkbox
            v-for="(service, optionServiceIndex) in optionServices"
            :key="optionServiceIndex"
            :value="service.value"
            v-model="selectedServices"
          >
            {{ service.text }}
          </b-form-checkbox>
        </b-card>
      </div>
      <div class="col-md-8">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Kontak PIC</h6>
          </template>
          <div
            class="row"
            v-for="(list, picIndex) in listKontakPIC"
            :key="picIndex"
          >
            <div class="col-md-5">
              <div class="mb-4">
                <h6 class="mb-1">Nama PIC</h6>
                <b-form-input
                  v-model="list.name"
                  placeholder="Masukan nama pic"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h6 class="mb-1">No Handphone</h6>
                <b-form-input
                  v-model="list.phone"
                  placeholder="Masukan no handphone"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row justify-content-center">
                <div class="col" v-if="listKontakPIC.length === picIndex + 1">
                  <b-button
                    variant="primary"
                    class="w-100"
                    style="margin-top: 21px"
                    @click="handleAddPic()"
                    >Add</b-button
                  >
                </div>
                <div class="col" v-if="listKontakPIC.length > 1">
                  <b-button
                    variant="danger"
                    class="w-100"
                    style="margin-top: 21px"
                    @click="handleDeletePic(picIndex)"
                    >Delete</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div> -->
    <div class="row gutter-b">
      <div class="col-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Jadwal & Shift</h6>
          </template>
          <div class="row">
            <div class="col-md-3">
              <div class="mb-4">
                <h6 class="mb-1">ID</h6>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <h6 class="mb-1">Shift</h6>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <h6 class="mb-1">Jam Mulai Shift</h6>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <h6 class="mb-1">Jam Selesai Shift</h6>
              </div>
            </div>
          </div>
          <div class="row" v-for="(list, index) in branch.shifts" :key="index">
            <div class="col-md-3">
              <div class="mb-4">
                <div>
                  {{ list.id }}
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <div>
                  {{ list.name }}
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <div>
                  {{ list.start }}
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <div>
                  {{ list.end }}
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Sector</h6>
          </template>
          <div class="row">
            <div class="col-md-3">
              <h6 class="mb-1">No</h6>
            </div>
            <div class="col-md-3">
              <h6 class="mb-1">Floor</h6>
            </div>
            <div class="col-md-3">
              <h6 class="mb-1">Sector</h6>
            </div>
            <div class="col-md-3">
              <h6 class="mb-1">QR</h6>
            </div>
          </div>
          <div class="row" v-for="(sector, key) in branch.sectors" :key="key">
            <div class="col-md-3">
              <div class="mb-4">
                <div>
                  {{ key + 1 }}
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <div>
                  {{ sector.floor }}
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <div>
                  {{ sector.sector }}
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <img
                  :src="sector.qr_path"
                  width="100px"
                  height="100px"
                  id="toggle-btn"
                  @click="showModalQR(sector)"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal ref="modal-qr-preview" hide-footer title="Preview QR">
      <div style="display: flex; justify-content: center" id="imageid">
        <img
          :src="selectedSector.qr_path"
          class="ml-auto mr-auto"
          width="350px"
          height="350px"
          referrerpolicy="origin-when-cross-origin"
        />
      </div>
      <b-button
        variant="primary"
        class="w-100"
        style="margin-top: 21px"
        @click="downloadPDF()"
        >Print</b-button
      >
      <b-button
        variant="primary"
        class="w-100"
        style="margin-top: 21px"
        @click="openNewTab()"
        >Open new tab</b-button
      >
    </b-modal>
    <div class="row gutter-b">
      <div class="col-12">
        <b-card header-tag="header" footer-tag="footer" class="gutter-b">
          <template v-slot:header>
            <h6 class="mb-0">Radius Absen</h6>
          </template>
          <div class="row m-0">
            <div class="col-md-6 p-0" v-if="markers.length">
              <GmapMap
                :center="center"
                :zoom="zoomMap"
                map-type-id="terrain"
                style="width: 96%; height: 312px"
              >
                <GmapCircle
                  v-for="(pin, indexCircle) in markers"
                  :key="indexCircle + 'a'"
                  :center="pin.position"
                  :radius="radiusMap"
                  :visible="false"
                  :options="{
                    fillColor: 'red',
                    fillOpacity: 0.2,
                    strokeColor: '#3151E2',
                    strokeWeight: 2,
                  }"
                ></GmapCircle>
                <GmapMarker
                  v-for="(m, indexMarker) in markers"
                  :key="indexMarker"
                  :position="m.position"
                />
              </GmapMap>
            </div>
            <div class="col-md-6 p-0">
              <div class="mb-6">
                <h6 class="mb-1">Alamat</h6>
                <div>
                  {{ branch.address }}
                </div>
              </div>
              <div class="mb-6">
                <h6 class="mb-1">Radius</h6>
                <div class="row m-0">
                  {{ branch.presence_distance_threshold }} m
                </div>
              </div>
              <div class="row mb-6">
                <div class="col-md-4">
                  <h6 class="mb-1">Pembatasan</h6>
                </div>
                <div class="col-md-4">
                  <b-form-checkbox
                    size="lg"
                    v-model="branch.forbid_check_in_outside_threshold"
                    disabled
                    >Check in</b-form-checkbox
                  >
                </div>
                <div class="col-md-4">
                  <b-form-checkbox
                    size="lg"
                    v-model="branch.forbid_check_out_outside_threshold"
                    disabled
                  >
                    Check out</b-form-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!--end:: Add customer-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { gmapApi } from "vue2-google-maps";
const pdfMake = require("pdfmake/build/pdfmake");
const pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "CustomerBranchView",
  data() {
    return {
      // PROFILE Customer
      branch: {},
      // MAPS
      markers: [],
      center: {
        lat: -6.209044569159681,
        lng: 106.84463747406414,
      },
      location: {},
      place: null,
      description: "",
      addressMap: "",
      zoomMap: 15,
      radiusMap: 200,
      selectedSector: {},
    };
  },
  components: {},
  computed: {
    google: gmapApi,
  },
  mounted() {
    this.getData();
    let lastBreadcrumb = {};
    let branchTitle = [
      {
        title: "Site Group",
        route: "/customer",
      },
      {
        title: "Site",
        route: `/customer/branch/list/${this.$route.params.customerId}`,
      },
    ];
    if (!this.$route.params.customerId)
      branchTitle = [
        {
          title: "Site Group",
          route: `/customer/branch/list`,
        },
      ];
    lastBreadcrumb = { title: "View" };
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      ...branchTitle,
      lastBreadcrumb,
    ]);
  },
  methods: {
    async getData() {
      try {
        const { data: getData } = await this.$axios.get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch/${this.$route.params.id}`
        );
        this.branch = getData;
        this.radius = getData.presence_distance_threshold;
        this.center = {
          lat: getData.latitude,
          lng: getData.longitude,
        };
        this.markers = [
          {
            position: {
              lat: getData.latitude,
              lng: getData.longitude,
            },
          },
        ];
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    showModalQR(item) {
      this.selectedSector = item;
      this.$refs["modal-qr-preview"].show();
    },
    async downloadPDF() {
      const customerBranchId = this.$route.params.id;
      await this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch/generate-qr`,
          {
            params: {
              customer_branch_id: customerBranchId,
              sector_id: this.selectedSector.id,
            },
          }
        )
        .then(({ data }) => {
          window.open(data.file);
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    openNewTab() {
      window.open(this.selectedSector);
    },
    async getBase64FromUrl() {
      try {
        const header = {
          referrerPolicy: "origin",
          // mode: "no-cors",
        };
        const url = this.selectedSector;
        const data = await fetch(url, header);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            const docDefinition = {
              content: [
                {
                  image: base64data,
                  width: 300,
                },
              ],
            };

            // Create the PDF document
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.download("new_pdf.pdf");
            resolve(base64data);
          };
        });
      } catch (_) {
        this.$bvToast.toast("error print", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    // getBase64Image(img) {
    //   let canvasElement = null;
    //   html2canvas(img, {
    //     "proxy": "https://cors-anywhere.herokuapp.com/",
    //     "logging": true,
    //   }
    //     ).then(function(canvas) {
    //     const doc = new jsPDF("p", "px", "a4");
    //     canvasElement = canvas;
    //     var dataURL = canvasElement.toDataURL("image/png");
    //     console.log("dataURL", dataURL);
    //     window.open(dataURL);
    //     doc.addImage(dataURL, "JPEG", 70, 40, 300, 300);
    //     // doc.save("qr-code.pdf");
    //   });
    // },
  },
};
</script>
